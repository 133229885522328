import React, { useContext } from "react"
import { Link } from "gatsby"
import Template from "../../components/template"
import "../../styles/blog/blogpost.css"
import { NoJusticeNoChoiceContent } from "../../content/blog/no-justice-no-choice"
import { SettingsContext } from "../../contexts/settings"

const NoJusticeNoChoicePage = () => {
    const [settings, ] = useContext(SettingsContext)
    const content = NoJusticeNoChoiceContent[settings.language]
    return (
        <Template currentPage="blog">
            <section>
            <div>
                <h1>
                    <Link to="/blog#no-justice-no-choice" class="back-arrow">{content.arrow}</Link>
                    {content.headline}
                </h1>
                <p>{content.date}</p>
                <p>
                    <b>{content.bigFirst}</b>
                    {content.first}
                </p>
                <p>
                    <b>{content.bigSecond}</b>
                    {content.secondFirst}
                    <a className="link" href="mailto:info@nocturnal-unrest.de">{content.secondLink}</a>
                    {content.secondSecond}
                </p>
                <p>
                    {content.third}
                </p>
                <p>
                    {content.fourthFirst}
                    <Link className="link-text-no-decoration" to="#footnote-1">&sup1;</Link>
                    {content.fourthSecond}
                    <a className="link" href="https://www.gen-ethisches-netzwerk.de/behinderung/246/die-fruhe-eugenik" target="_blank" rel="noopener noreferrer">{content.fourthLink}</a>
                    {content.fourthThird}
                </p>
                <p>
                    {content.fifth}
                    <a className="link" href="https://missy-magazine.de/blog/2020/03/17/die-grenze/" target="_blank" rel="noopener noreferrer">{content.fifthLink}</a>
                </p>
                <p>
                    {content.sixthFirst}
                    <a className="link" href="https://anschlaege.at/die-idee-der-absoluten-kontrolle-ist-total-antiemanzipatorisch/" target="_blank" rel="noopener noreferrer">{content.sixthLink}</a>
                    {content.sixthSecond}
                </p>
                <p>
                    {content.seventh}
                    <a className="link" href="https://www.gwi-boell.de/de/2017/09/18/uneingeschraenkt-eltern-sein" target="_blank" rel="noopener noreferrer">{content.seventhLink}</a>
                </p>
                <p>
                    {content.eighthFirst}
                    <a className="link" href="https://www.aaihs.org/black-feminist-alchemy-reproductive-justice-and-the-carceral-state/" target="_blank" rel="noopener noreferrer">{content.eighthLinkFirst}</a>
                    {content.eighthSecond}
                    <a className="link" href="https://open.spotify.com/episode/6OSnwALeT9WnSRFrFEUh3G?si=f0Lg5ji3RcK3yoLSvRf_QQ.&nd=1" target="_blank" rel="noopener noreferrer">{content.eighthLinkSecond}</a>
                    {content.eighthThird}
                    <a className="link" href="https://open.spotify.com/episode/2yb24kMXU8UHXlKrjXIK9S?si=W6MmPKC0RMimxYmD2o_fmw.&nd=1" target="_blank" rel="noopener noreferrer">{content.eighthLinkThird}</a>
                    {content.eighthFourth}
                    <a className="link" href="https://www.nbcnews.com/news/nbcblk/black-women-fight-abortion-rights-how-brochure-sparked-movement-reproductive-n983216" target="_blank" rel="noopener noreferrer">{content.eighthLinkFourth}</a>

                </p>
                <p>
                    {content.ninthFirst}
                    <a className="link" href="https://www.zeitschrift-luxemburg.de/no-justice-no-choice/" target="_blank" rel="noopener noreferrer">{content.ninthLinkFirst}</a>
                    {content.ninthSecond}
                    <a className="link" href="https://www.gwi-boell.de/de/2018/03/16/mehr-als-pro-choice" target="_blank" rel="noopener noreferrer">{content.ninthLinkSecond}</a>
                </p>
                <p>
                    {content.tenth}
                </p>
                <p>
                    <b>{content.bigThird}</b>
                </p>
                <div className="white-line"></div>
                <p id="footnote-1" className="end-of-site text-middle">
                    &sup1;
                    {content.eleventhFirst}
                    <a className="link-middle" href="https://www.sapiens.org/biology/is-race-real/" target="_blank" rel="noopener noreferrer">{content.eleventhLink}</a>
                    {content.eleventhSecond}
                </p>
            </div>    
            </section>
        </Template >
    )
}

export default NoJusticeNoChoicePage