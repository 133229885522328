const NoJusticeNoChoiceContent = {
    german: {
        date: "5. Februar 2021",
        arrow: "BLOG << ",
        headline: "„No Justice, No Choice“ pro-choice intersectional denken",
        bigFirst: "Content Warning:",
        first: " Seximus, Rassismus, Ableismus, Gewalt, Zwang, Schwangerschaftsabbruch",
        bigSecond: "Vorbemerkung:",
        secondFirst: " Auch wir sind erst vor Kurzem auf die Debatten und Kritiken rund um reproduktive Gerechtigkeit gestoßen. Wir sind also weder Expert:innen auf dem Gebiet, noch direkt betroffen, oder besonders tief eingelesen. Wenn wir etwas im Folgenden nicht gut dargestellt haben, oder vergessen haben, freuen wir uns immer über Kritik oder Hinweise an ",
        secondLink: "info@nocturnal-unrest.de",
        secondSecond: ". Im Sinne eines intersektionalen Feminismus möchten wir im Folgenden dazu beitragen, Aufmerksamkeit für das Thema zu schaffen und ein paar Ressourcen aus unserer Recherche zum Einlesen, Weiterlesen und eigene-Meinung-Bilden teilen. Wir haben bei der Auswahl darauf geachtet, dass die Beiträge mehrheitlich von Betroffenen verfasst wurden und kostenlos zugänglich sind. Um einen kleinen Überblick zu bekommen, haben wir versucht die Leseempfehlungen zu kontextualisieren und/oder kurz zusammen zu fassen.",
        third: "Spätestens seitdem die 68er-Feminist:innen das Abtreibungsverbots im §218 des Deutschen Strafgesetzbuches – wohlgemerkt direkt neben anderen „Straftaten gegen das Leben“ wie Totschlag – skandalisiert haben, ist der Kampf für das Recht auf Abtreibung als Recht auf Entscheidung über den eigenen Körper zentral in der feministischen Bewegung in Deutschland.",
        fourthFirst: "Viel zu lange wurde und wird immer noch über den Körper von cis und trans Frauen und über deren reproduktive Organe in patriarchalen nationalistischen Gesellschaften bestimmt als wären sie bloße „Gefäße“ und Gebärmaschinen, als wären sie bloße Mittel zum Zweck der „Reproduktion der Nation“. Kein Wunder, dass die genannten Paragraphen auch Relikte aus der NS-Zeit aufweisen. Die fatale Verschränkung von Schwangerschaft, Geburt und Nation wurzelt unter anderem im NS-Faschismus, was bis heute in so mancher AfD-Kampagne oder christlich-fundamentalistischer „pro-life“-Kampagne fortwirkt. Allerdings gab es tatsächlich auch sozialistische und feministische Formen der Eugenik",
        fourthSecond: " im 19. und 20. Jahrhundert, wie das ",
        fourthLink: "gen-ethische Netzwerk im deutschsprachigen Beitrag schreibt, zu dem ihr über diesen Link gelangt",
        fourthThird: ". Sozial-Darwinismus, Ableismus und Rassismus haben also einen ebenso starken Einfluss auf Diskussionen rund um Körper, Leben, Geburt und Tod wie Sexismus, Transfeindlichkeit und Misogynie. Deshalb finden wir, dass wir als Feminist:innen hinsichtlich dessen besonders aufmerksam sein müssen.",
        fifth: "Vor allem be_hinderte Feminist:innen und Schwarze Feminist:innen kritisieren, dass die Verengung der Debatte auf pro choice contra pro life nicht genügend über die Einflüsse von Rassismus und Ableismus reflektiert. Insbesondere dass bei Feststellung von Behinderungen des werdenden Kindes in der Pränataldiagnostik im Rahmen der bestehenden Gesetze bedeutend länger abgebrochen werden kann, ist das durchaus problematisch, wie die Sozialwissenschaftlerin Kirsten Achtelik im Missy Magazine schreibt: es bestehe die Gefahr, dass „kranke“ oder be_hinderte Kinder „aussortiert“ werden, weil sie nicht der gesellschaftlichen Norm eines „gewünschten Kindes“ entsprechen. Feministische Debatten ebenso wie ein gerechtes Abtreibungsrecht müssten das mitbedenken, denn „wenn die eigene Angst vor Behinderung und der gesellschaftliche Druck die Handlungsoptionen der Schwangeren beschränken, ist es schwer zu sagen, wie selbstbestimmt diese Entscheidung überhaupt war.“ ",
        fifthLink: "Den Artikel könnt ihr unter diesem Link zum Missy Magazine vom März 2020 lesen.",
        sixthFirst: "Achtelik hat auch der feministischen Zeitschrift an.schläge ",
        sixthLink: "ein kritisches deutschsprachiges Interview gegeben, das ihr unter diesem Link findet.",
        sixthSecond: " Darin geht sie auch auf die früheren eugenischen Positionen in Strömungen der deutschen feministischen Bewegung ein. Sie kritisiert auch selektive Tendenzen in den Kategorien „gesund“ und „krank“ der medizinischen Sprache. Dabei verdeutlicht sie, dass es niemals die individuelle schwangere Person ist, der die Kritik gilt, sondern eine ableistische Gesellschaft, die Menschen aktiv be_hindert und dann aufgrund dieser Behinderung marginalisiert. Daher müssten Feminist:innen ihre Forderungen konsequent um anti-ableistische Positionen erweitern und den Staat in die Verantwortung nehmen. Sie schlägt außerdem vor über Selbstbestimmung neu nachzudenken: „Meine Forderung an die feministische Bewegung ist, den eigenen Anspruch, eine emanzipatorische Bewegung zu sein, ernst zu nehmen und intersektional gegen verschiedene Unterdrückungsmechanismen zu kämpfen. Die Idee der absoluten Kontrolle ist total antiemanzipatorisch“, sagt sie.",
        seventh: "Darüber hinaus gibt es eine lange, dunkle Geschichte der Zwangssterilisation/-abbrüche bei be_hinderten Menschen zu beachten, sowie bestehende Barrieren als be_hinderte Person selbstbestimmt für oder gegen Kinder zu entscheiden. Das, so fordern einige zu Recht, sollte auch Thema einer Debatte um gerechte Regelungen rund um die Entscheidungen über den eigenen Körper sein (",
        seventhLink: "eine spannende Reportage aus Text und Bild dazu gibt es beim Klick auf diesen Link).",
        eighthFirst: "Feministische Debatten um Schwangerschaftsabbrüche neigen also manchmal dazu die Thematik nicht breit genug anzugehen. Unter dem Schlagwort reproductive justice/reproduktive Gerechtigkeit versuchen auch Schwarze Feminist:innen die Debatte um eine notwendige intersektionale Perspektive zu erweitern. Denn: Schwarzes Leben ist in kolonialen und post-kolonialen/post-abolitionistischen Gesellschaften dehumanisiert und gefährdet; medizinhistorisch gibt es zahlreiche, grauenhafte Belege für die Ausbeutung, Verstümmelung und den Missbrauch Schwarzer Körper und ihre reproduktiven Organe (z.B. im englischsprachigen Buch „Ghost Stories for Darwin“ von Banu Subramaniam, ",
        eighthLinkFirst: "und in diesem englischsprachigen Artikel",
        eighthSecond: " von Dan Berger). Ihren Anfang nahm die Bewegung in den USA, ",
        eighthLinkSecond: "wie ihr im englischen Podcast von LaKia Williams unter desem Link nachhören könnt",
        eighthThird: ". (Es gibt auch eine ",
        eighthLinkThird: "Podcast-Folge mit einer der Initiatorinnen der Bewegung, Loretta Ross unter diesem Link)",
        eighthFourth: ". Wer lieber lesen möchte, kann sich z.B. diesen englischsprachigen Beitrag von Natelegé Whaley über die ",
        eighthLinkFourth: "Geschichte und Bedeutung des reproductive freedom movements in den USA zu Gemüte führen, indem mensch hier klickt.",
        ninthFirst: "Hannah Schurian erklärt vor diesem Hintergrund ",
        ninthLinkFirst: "in einem deutschsprachigen Beitrag für die Rosa-Luxemburg-Stiftung unter dem Titel „No justice, no choice“ „was sexuelle Selbstbestimmung mit reproduktiver Gerechtigkeit zu tun hat“:",
        ninthSecond: " Und Peggy Piesche erläutert in „Mehr als Pro Choice“, was die Feminist:innen der 68er für uns alle erkämpft haben – und wer dabei dennoch aufgrund rassistischer Strukturen ausgeschlossen und übersehen wurde. Wie können wir echte reproduktive Gerechtigkeit für alle erkämpfen? Melody Makeda Ledwon, Maureen Maisha Auma und Karina Griffith geben Korrekturen der 68er Geschichtsschreibungen zur Protokoll und machen Vorschläge, um pro-choice intersektional weiterdenken. ",
        ninthLinkSecond: "Ihr gelangt über diesen Link zum deutschsprachigen Artikel.",
        tenth: "Die Debatte intersektional zu führen bedeutet darüber hinaus auf eine trans-inklusive Sprache zu führen, d.h. nicht entgegen der Realität davon auszugehen, dass nur cis Frauen von §218 betroffen wären. Es tut uns leid, das manche Artikel, die wir aufgrund ihrer gut nachvollziehbaren Argumentation teilen, dem nicht gerecht werden. Wir arbeiten daran uns hier noch besser einzulesen und diesen Beitrag um entsprechende Ressourcen zu Aspekten reproduktiver Gerechtigkeit für trans Personen zu erweitern.",
        bigThird: "Damit der Kampf für pro-choice, dem wir uns als nOu-Kollektiv explizit anschließen, ein Kampf für die Selbstbestimmung aller ist!",
        eleventhFirst: " Eugenik bezeichnet eine Pseudowissenschaft, die sich damit beschäftigt wie sich menschliche „Rassen“ (deren ",
        eleventhLink: "Existenz wissenschaftlich widerlegt ist",
        eleventhSecond: ") durch Selektion, gezielte Tötungen und „Züchtung“ verbessern lassen. Sie basiert auf rassistischen und sozial-darwinistischen Prämissen und erlebte ihre Hochzeit u.a. im Kolonialismus und im NS-Faschismus."
    },
    english: {
        date: "February 5, 2021",
        arrow: "BLOG << ",
        headline: "„No Justice, No Choice“ pro-choice intersectional denken",
        bigFirst: "Content Warning:",
        first: " Seximus, Rassismus, Ableismus, Gewalt, Zwang, Schwangerschaftsabbruch",
        bigSecond: "Vorbemerkung:",
        secondFirst: " Auch wir sind erst vor Kurzem auf die Debatten und Kritiken rund um reproduktive Gerechtigkeit gestoßen. Wir sind also weder Expert:innen auf dem Gebiet, noch direkt betroffen, oder besonders tief eingelesen. Wenn wir etwas im Folgenden nicht gut dargestellt haben, oder vergessen haben, freuen wir uns immer über Kritik oder Hinweise an ",
        secondLink: "info@nocturnal-unrest.de",
        secondSecond: ". Im Sinne eines intersektionalen Feminismus möchten wir im Folgenden dazu beitragen, Aufmerksamkeit für das Thema zu schaffen und ein paar Ressourcen aus unserer Recherche zum Einlesen, Weiterlesen und eigene-Meinung-Bilden teilen. Wir haben bei der Auswahl darauf geachtet, dass die Beiträge mehrheitlich von Betroffenen verfasst wurden und kostenlos zugänglich sind. Um einen kleinen Überblick zu bekommen, haben wir versucht die Leseempfehlungen zu kontextualisieren und/oder kurz zusammen zu fassen.",
        third: "Spätestens seitdem die 68er-Feminist:innen das Abtreibungsverbots im §218 des Deutschen Strafgesetzbuches – wohlgemerkt direkt neben anderen „Straftaten gegen das Leben“ wie Totschlag – skandalisiert haben, ist der Kampf für das Recht auf Abtreibung als Recht auf Entscheidung über den eigenen Körper zentral in der feministischen Bewegung in Deutschland.",
        fourthFirst: "Viel zu lange wurde und wird immer noch über den Körper von cis und trans Frauen und über deren reproduktive Organe in patriarchalen nationalistischen Gesellschaften bestimmt als wären sie bloße „Gefäße“ und Gebärmaschinen, als wären sie bloße Mittel zum Zweck der „Reproduktion der Nation“. Kein Wunder, dass die genannten Paragraphen auch Relikte aus der NS-Zeit aufweisen. Die fatale Verschränkung von Schwangerschaft, Geburt und Nation wurzelt unter anderem im NS-Faschismus, was bis heute in so mancher AfD-Kampagne oder christlich-fundamentalistischer „pro-life“-Kampagne fortwirkt. Allerdings gab es tatsächlich auch sozialistische und feministische Formen der Eugenik",
        footnote: "[1] ",
        fourtSecond: " im 19. und 20. Jahrhundert, wie das ",
        fourthLink: "gen-ethische Netzwerk im deutschsprachigen Beitrag schreibt, zu dem ihr über diesen Link gelangt",
        fourthThird: ". Sozial-Darwinismus, Ableismus und Rassismus haben also einen ebenso starken Einfluss auf Diskussionen rund um Körper, Leben, Geburt und Tod wie Sexismus, Transfeindlichkeit und Misogynie. Deshalb finden wir, dass wir als Feminist:innen hinsichtlich dessen besonders aufmerksam sein müssen.",
        fifth: "Vor allem be_hinderte Feminist:innen und Schwarze Feminist:innen kritisieren, dass die Verengung der Debatte auf pro choice contra pro life nicht genügend über die Einflüsse von Rassismus und Ableismus reflektiert. Insbesondere dass bei Feststellung von Behinderungen des werdenden Kindes in der Pränataldiagnostik im Rahmen der bestehenden Gesetze bedeutend länger abgebrochen werden kann, ist das durchaus problematisch, wie die Sozialwissenschaftlerin Kirsten Achtelik im Missy Magazine schreibt: es bestehe die Gefahr, dass „kranke“ oder be_hinderte Kinder „aussortiert“ werden, weil sie nicht der gesellschaftlichen Norm eines „gewünschten Kindes“ entsprechen. Feministische Debatten ebenso wie ein gerechtes Abtreibungsrecht müssten das mitbedenken, denn „wenn die eigene Angst vor Behinderung und der gesellschaftliche Druck die Handlungsoptionen der Schwangeren beschränken, ist es schwer zu sagen, wie selbstbestimmt diese Entscheidung überhaupt war.“ ",
        fifthLink: "Den Artikel könnt ihr unter diesem Link zum Missy Magazine vom März 2020 lesen.",
        sixthFirst: "Achtelik hat auch der feministischen Zeitschrift an.schläge ",
        sixthLink: "ein kritisches deutschsprachiges Interview gegeben, das ihr unter diesem Link findet.",
        sixthSecond: " Darin geht sie auch auf die früheren eugenischen Positionen in Strömungen der deutschen feministischen Bewegung ein. Sie kritisiert auch selektive Tendenzen in den Kategorien „gesund“ und „krank“ der medizinischen Sprache. Dabei verdeutlicht sie, dass es niemals die individuelle schwangere Person ist, der die Kritik gilt, sondern eine ableistische Gesellschaft, die Menschen aktiv be_hindert und dann aufgrund dieser Behinderung marginalisiert. Daher müssten Feminist:innen ihre Forderungen konsequent um anti-ableistische Positionen erweitern und den Staat in die Verantwortung nehmen. Sie schlägt außerdem vor über Selbstbestimmung neu nachzudenken: „Meine Forderung an die feministische Bewegung ist, den eigenen Anspruch, eine emanzipatorische Bewegung zu sein, ernst zu nehmen und intersektional gegen verschiedene Unterdrückungsmechanismen zu kämpfen. Die Idee der absoluten Kontrolle ist total antiemanzipatorisch“, sagt sie.",
        seventh: "Darüber hinaus gibt es eine lange, dunkle Geschichte der Zwangssterilisation/-abbrüche bei be_hinderten Menschen zu beachten, sowie bestehende Barrieren als be_hinderte Person selbstbestimmt für oder gegen Kinder zu entscheiden. Das, so fordern einige zu Recht, sollte auch Thema einer Debatte um gerechte Regelungen rund um die Entscheidungen über den eigenen Körper sein (",
        seventhLink: "eine spannende Reportage aus Text und Bild dazu gibt es beim Klick auf diesen Link).",
        eighthFirst: "Feministische Debatten um Schwangerschaftsabbrüche neigen also manchmal dazu die Thematik nicht breit genug anzugehen. Unter dem Schlagwort reproductive justice/reproduktive Gerechtigkeit versuchen auch Schwarze Feminist:innen die Debatte um eine notwendige intersektionale Perspektive zu erweitern. Denn: Schwarzes Leben ist in kolonialen und post-kolonialen/post-abolitionistischen Gesellschaften dehumanisiert und gefährdet; medizinhistorisch gibt es zahlreiche, grauenhafte Belege für die Ausbeutung, Verstümmelung und den Missbrauch Schwarzer Körper und ihre reproduktiven Organe (z.B. im englischsprachigen Buch „Ghost Stories for Darwin“ von Banu Subramaniam, ",
        eighthLinkFirst: "und in diesem englischsprachigen Artikel",
        eighthSecond: " von Dan Berger). Ihren Anfang nahm die Bewegung in den USA, ",
        eighthLinkSecond: "wie ihr im englischen Podcast von LaKia Williams unter desem Link nachhören könnt",
        eighthThird: ". (Es gibt auch eine ",
        eighthLinkThird: "Podcast-Folge mit einer der Initiatorinnen der Bewegung, Loretta Ross unter diesem Link)",
        eighthFourth: ". Wer lieber lesen möchte, kann sich z.B. diesen englischsprachigen Beitrag von Natelegé Whaley über die ",
        eighthLinkFourth: "Geschichte und Bedeutung des reproductive freedom movements in den USA zu Gemüte führen, indem mensch hier klickt.",
        ninthFirst: "Hannah Schurian erklärt vor diesem Hintergrund ",
        ninthLinkFirst: "in einem deutschsprachigen Beitrag für die Rosa-Luxemburg-Stiftung unter dem Titel „No justice, no choice“ „was sexuelle Selbstbestimmung mit reproduktiver Gerechtigkeit zu tun hat“:",
        ninthSecond: " Und Peggy Piesche erläutert in „Mehr als Pro Choice“, was die Feminist:innen der 68er für uns alle erkämpft haben – und wer dabei dennoch aufgrund rassistischer Strukturen ausgeschlossen und übersehen wurde. Wie können wir echte reproduktive Gerechtigkeit für alle erkämpfen? Melody Makeda Ledwon, Maureen Maisha Auma und Karina Griffith geben Korrekturen der 68er Geschichtsschreibungen zur Protokoll und machen Vorschläge, um pro-choice intersektional weiterdenken. ",
        ninthLinkSecond: "Ihr gelangt über diesen Link zum deutschsprachigen Artikel.",
        tenth: "Die Debatte intersektional zu führen bedeutet darüber hinaus auf eine trans-inklusive Sprache zu führen, d.h. nicht entgegen der Realität davon auszugehen, dass nur cis Frauen von §218 betroffen wären. Es tut uns leid, das manche Artikel, die wir aufgrund ihrer gut nachvollziehbaren Argumentation teilen, dem nicht gerecht werden. Wir arbeiten daran uns hier noch besser einzulesen und diesen Beitrag um entsprechende Ressourcen zu Aspekten reproduktiver Gerechtigkeit für trans Personen zu erweitern.",
        bigThird: "Damit der Kampf für pro-choice, dem wir uns als nOu-Kollektiv explizit anschließen, ein Kampf für die Selbstbestimmung aller ist!",
        eleventhFirst: " Eugenik bezeichnet eine Pseudowissenschaft, die sich damit beschäftigt wie sich menschliche „Rassen“ (deren ",
        eleventhLink: "Existenz wissenschaftlich widerlegt ist",
        eleventhSecond: ") durch Selektion, gezielte Tötungen und „Züchtung“ verbessern lassen. Sie basiert auf rassistischen und sozial-darwinistischen Prämissen und erlebte ihre Hochzeit u.a. im Kolonialismus und im NS-Faschismus."
    }

}
export {NoJusticeNoChoiceContent}